import { AirportTransferDirection } from "@daytrip/legacy-enums";
import { transformBooleanStringToBoolean } from "@daytrip/legacy-transformers";
import { transformSimpleUsersToSelectOptions } from "@daytrip/legacy-transformers";
import { transformTimestampStringToDate } from "@daytrip/legacy-transformers";
import { isUndefinedOrNull } from "@daytrip/utils";
import type { OrderDataForOrdersPage } from "@legacy/dataTransferObjects/OrderDataForOrdersPage";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { RetrieveOrdersOptions } from "@legacy/options/RetrieveOrdersOptions";
import type { RetrieveUsersOptions } from "@legacy/options/RetrieveUsersOptions";
import autobind from "autobind-decorator";
import { validate } from "class-validator";
import { action, observable } from "mobx";
import type { Option } from "react-select-legacy";

import { PageStore } from "../../stores/PageStore";

import type { OrdersPageRouter } from "./OrdersPageRouter";

@autobind
export class OrdersPageStore extends PageStore<OrdersPageRouter, null> {
    @observable
    public orders?: Array<OrderDataForOrdersPage>;

    @observable
    public ordersCount?: number;

    @observable
    public isOrdersFetching: boolean = false;

    @observable
    public countries?: Array<SimpleCountry>;

    public options?: RetrieveOrdersOptions;

    @action
    public clearSearch() {
        this.pageRouter.clearQuery();
        this.fetchData();
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchLocations(value: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (value && value.length > 2) {
            const locations = await this.rpcClient.content.retrieveSimpleLocations({
                isDestination: true,
                searchString: value,
            });

            options = locations.map((location) => ({
                value: location.locationId,
                label: location.name + (location.countryName ? `, ${location.countryName}` : ""),
            }));
        }

        return { options };
    }

    @action
    public async usersFetch(value: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (value && value.length > 2) {
            const userOptions = {
                searchString: value,
                isDriver: false,
                isDriversCompany: false,
                isCompanyDriver: false,
                isCustomer: true,
            } as RetrieveUsersOptions;
            const users = await this.rpcClient.user.retrieveSimpleUsers(userOptions);
            options = transformSimpleUsersToSelectOptions(users);
        }

        return { options };
    }

    @action
    public async driversFetch(value: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (value && value.length > 2) {
            const userOptions = {
                searchString: value,
                isDriver: true,
                isDriversCompany: true,
                isCompanyDriver: true,
            } as RetrieveUsersOptions;
            const users = await this.rpcClient.user.retrieveSimpleUsers(userOptions);
            options = transformSimpleUsersToSelectOptions(users);
        }

        return { options };
    }

    @action
    public async apiPartnersFetch(value: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (value && value.length > 2) {
            const apiPartners = await this.rpcClient.apiPartner.getApiPartnersByName(value);
            options = apiPartners.map((apiPartner) => ({
                label: apiPartner.name,
                value: apiPartner._id,
            }));
        }

        return { options };
    }

    @action
    public async countriesFetch() {
        this.countries = await this.rpcClient.content.retrieveSimpleCountries({});
    }

    /**
     * Since this filter works with a single boolean value, we need to transform it to an array of values.
     * If the filter is on, we retrieve both directions, if it is off we retrieve direction none as well as any
     * nullish values for airportTransferDirection.
     */
    private getAirportTransferFilterValue(
        isAirportTransferAsFilter: string | undefined,
    ): AirportTransferDirection[] | undefined {
        const isAirportTransfer = transformBooleanStringToBoolean(isAirportTransferAsFilter);
        if (isUndefinedOrNull(isAirportTransfer)) {
            return;
        }

        if (isAirportTransfer) {
            return [AirportTransferDirection.FromAirport, AirportTransferDirection.ToAirport];
        }

        return [AirportTransferDirection.None];
    }

    @action
    public async fetchContent() {
        const filters = this.pageRouter;

        this.orders = undefined;

        this.isOrdersFetching = true;

        this.options = {
            createdAtFrom: transformTimestampStringToDate(filters.createdAtFrom),
            createdAtTo: transformTimestampStringToDate(filters.createdAtTo),
            departureAtFrom: transformTimestampStringToDate(filters.departureAtFrom),
            departureAtTo: transformTimestampStringToDate(filters.departureAtTo),
            originLocationIds: filters.originLocationIds,
            destinationLocationIds: filters.destinationLocationIds,
            statusIn: filters.statusIn,
            userIds: filters.userIds,
            voucher: filters.voucher,
            driverIds: filters.driverIds,
            apiPartnerIds: filters.apiPartnerIds,
            skip: filters.skip,
            limit: filters.limit,
            sortBy: filters.sortBy,
            sortDirection: filters.sortDirection,
            isFeedbackRequestDisabled: transformBooleanStringToBoolean(filters.isFeedbackRequestDisabled),
            isDepositRequired: transformBooleanStringToBoolean(filters.isDepositRequired),
            onlyApiPartnerOrders: transformBooleanStringToBoolean(filters.onlyApiPartnerOrders),
            onlyTravelAgentOrders: transformBooleanStringToBoolean(filters.onlyTravelAgentOrders),
            onlyVIPOrders: transformBooleanStringToBoolean(filters.onlyVIPOrders),
            originCountryIds: filters.originCountryIds,
            destinationCountryIds: filters.destinationCountryIds,
            passengerCountryIds: filters.passengerCountryIds,
            hasStops: transformBooleanStringToBoolean(filters.hasStops),
            hasChild: transformBooleanStringToBoolean(filters.hasChild),
            type: filters.orderType,
            offerType: filters.offerType,
            vehicleTypesIn: filters.vehicleTypesIn,
            airportTransferDirection: this.getAirportTransferFilterValue(filters.isAirportTransfer),
            bundleId: this.pageRouter.bundleId,
        } as RetrieveOrdersOptions;

        const optionsValidationErrors = await validate(this.options, { skipMissingProperties: true });
        if (optionsValidationErrors.length > 0) {
            return Promise.reject(new Error("Validation error"));
        }

        const [orders, ordersCount] = await Promise.all([
            this.rpcClient.order.retrieveOrdersDataForOrdersPage(this.options),
            this.rpcClient.order.retrieveOrdersCount(this.options),
        ]);

        if (!this.orders) {
            this.orders = orders;
            this.ordersCount = ordersCount;
        }
        this.isOrdersFetching = false;
        return undefined;
    }

    @observable
    public orderPricesExportRequested?: boolean;

    @action
    public requestOrdersPricesExport(): void {
        this.options = this.options as RetrieveOrdersOptions;
        this.options.skip = undefined;
        this.options.limit = undefined;

        this.rpcClient.export.requestOrdersPricesExport(this.options as RetrieveOrdersOptions);
        this.orderPricesExportRequested = true;

        setTimeout(() => {
            this.orderPricesExportRequested = false;
        }, 3000);
    }

    public isDataFetched(): this is OrdersPageStore & OrdersPageStoreDataFetched {
        return this.ordersCount !== undefined;
    }
}

export interface OrdersPageStoreDataFetched {
    orders: Array<OrderDataForOrdersPage>;
    ordersCount: number;
}
