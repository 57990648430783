import type { OfferType } from "@daytrip/legacy-enums";
import { AirportTransferDirection } from "@daytrip/legacy-enums";
import type { Order } from "@daytrip/legacy-models";
import { Exclude, Expose, Transform } from "class-transformer";
import { IsOptional, IsArray, IsBoolean, IsDate, IsEnum, IsInt, IsNumber, IsString } from "class-validator";

import type { OrderAutomaticEmailType } from "../domain/OrderAutomaticEmailType";
import type { OrderStatus } from "../domain/OrderStatus";
import { OrderType } from "../domain/OrderType";
import type { PaymentMethod } from "../domain/PaymentMethod";
import type { VehicleType } from "../domain/VehicleType";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

@Exclude()
export class RetrieveOrdersOptions extends SortingPaginationOptions<Order> {
    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public ids?: Array<string>;

    @IsString()
    @Expose()
    public bundleId?: string;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public createdAtFrom?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public createdAtTo?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public departureAtFrom?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public departureAtTo?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public arrivalTimeTo?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public confirmedAtFrom?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public confirmedAtTo?: Date;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public originLocationIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public destinationLocationIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public contentLocationIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public hasDiscountId?: boolean;

    // TODO validate uuids
    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public discountIds?: Array<string>;

    // TODO validate uuids
    @IsArray()
    @Expose()
    @Transform((value) => {
        if (value == undefined) {
            return undefined;
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        return (value as Array<typeof value>).map((item) => String(item));
    })
    public userIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => {
        if (value == undefined) {
            return undefined;
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        return (value as Array<typeof value>).map((item) => String(item));
    })
    public apiPartnerIds?: Array<string>;

    @IsString()
    @Expose()
    public voucher?: string;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public driverIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public hasAllDriversAccepted?: boolean;

    @IsBoolean()
    @Expose()
    public hasAllAssignationsAssigned?: boolean;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => Number(item))))
    public statusIn?: Array<OrderStatus>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => Number(item))))
    public vehicleTypesIn?: Array<VehicleType>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => Number(item))))
    public vehicleTypesNotIn?: Array<VehicleType>;

    @IsBoolean()
    @Expose()
    public hasAssignations?: boolean;

    @IsBoolean()
    @Expose()
    public declinedAssignations?: boolean;

    @IsBoolean()
    @Expose()
    public isPickupAddressApproved?: boolean;

    @IsBoolean()
    @Expose()
    public isDropoffAddressApproved?: boolean;

    @IsArray()
    @Expose()
    public emailTypesSent?: Array<OrderAutomaticEmailType>;

    @IsArray()
    @Expose()
    public emailTypesNotSent?: Array<OrderAutomaticEmailType>;

    @IsBoolean()
    @Expose()
    public isFeedbackRequestDisabled?: boolean;

    @IsBoolean()
    @Expose()
    public isDepositRequired?: boolean;

    @IsBoolean()
    @Expose()
    public onlyApiPartnerOrders?: boolean;

    @IsBoolean()
    @Expose()
    public onlyTravelAgentOrders?: boolean;

    @IsBoolean()
    @Expose()
    public onlyVIPOrders?: boolean;

    @IsString()
    @Expose()
    public search?: string;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public originCountryIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public destinationCountryIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public passengerCountryIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public hasStops?: boolean;

    @IsBoolean()
    @Expose()
    public hasChild?: boolean;

    @IsNumber()
    @Expose()
    public routePopularityFrom?: number;

    @IsNumber()
    @Expose()
    public routePopularityTo?: number;

    @IsNumber()
    @Expose()
    public maxPrice?: number;

    @IsArray()
    @Expose()
    public automaticEmailMailgunIds?: Array<string>;

    @IsString()
    @Expose()
    public partnerId?: string;

    @IsString()
    @Expose()
    public apiPartnerId?: string;

    @IsArray()
    @Expose()
    public paymentMethods?: Array<PaymentMethod>;

    @IsInt()
    @Expose()
    public type?: OrderType;

    @IsInt()
    @Expose()
    public offerType?: OfferType | 999;

    @IsArray()
    @IsString({ each: true })
    @Expose()
    @Transform((value) => {
        if (value == undefined) {
            return undefined;
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        return (value as Array<typeof value>).map((item) => String(item));
    })
    public hostAgencyIds?: Array<string>;

    @IsOptional()
    @IsArray()
    @IsEnum(AirportTransferDirection, { each: true })
    @Expose()
    public airportTransferDirection?: AirportTransferDirection[];
}
