import { AirportTransferDirection } from "@daytrip/legacy-enums";
import { Exclude, Expose, Type } from "class-transformer";
import {
    ArrayNotEmpty,
    IsArray,
    IsBoolean,
    IsDate,
    IsDefined,
    IsNumber,
    IsString,
    IsUUID,
    Length,
    ValidateNested,
    IsEnum,
} from "class-validator";

import { Currency } from "./Currency";
import type { LuggageType } from "./LuggageType";
import { OrderStatus } from "./OrderStatus";
import { OrderType } from "./OrderType";
import { PaymentMethod } from "./PaymentMethod";
import { SimpleOrderContentLocation } from "./SimpleOrderContentLocation";
import { SimpleOrderCustomLocation } from "./SimpleOrderCustomLocation";
import { SimplePassenger } from "./SimplePassenger";
import type { VehicleType } from "./VehicleType";

@Exclude()
export class SimpleOrder {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public orderId: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public userId: string;

    @IsString()
    @IsDefined()
    @Expose()
    public userFullName: string;

    @IsString()
    @IsDefined()
    @Expose()
    public userEmail: string;

    @IsNumber()
    @IsDefined()
    @Expose()
    public price: number;

    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public pricingCurrency: Currency = Currency.Euro;

    @IsNumber()
    @IsDefined()
    @Expose()
    public fee: number;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isPaid?: boolean;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public paidAmount: number;

    @IsNumber()
    @IsDefined()
    @Expose()
    public status: OrderStatus;

    @IsDate()
    @Expose()
    @Type(() => Date)
    public departureAt: Date;

    @IsDate()
    @IsDefined()
    @Expose()
    @Type(() => Date)
    public arrivalAt?: Date;

    @IsDate()
    @IsDefined()
    @Expose()
    @Type(() => Date)
    public createdAt: Date;

    @ArrayNotEmpty()
    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => Number)
    public vehicles: Array<VehicleType> = [];

    @ArrayNotEmpty()
    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => SimplePassenger)
    public simplePassengers: Array<SimplePassenger> = [];

    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => Number)
    public luggage: Array<LuggageType>;

    @Length(1, 64)
    @IsString()
    @IsDefined()
    @Expose()
    public originLocationName: string;

    @IsString()
    @IsDefined()
    @Expose()
    public originLocationTimeZone: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public originLocationId: string;

    @Length(1, 64)
    @IsString()
    @IsDefined()
    @Expose()
    public destinationLocationName: string;

    @IsString()
    @IsDefined()
    @Expose()
    public destinationLocationTimeZone: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public destinationLocationId: string;

    @IsArray()
    @ValidateNested()
    @Expose()
    @Type(() => SimpleOrderContentLocation)
    public simpleOrderContentLocations: Array<SimpleOrderContentLocation> = [];

    @IsArray()
    @ValidateNested()
    @Expose()
    @Type(() => SimpleOrderCustomLocation)
    public simpleOrderCustomLocations: Array<SimpleOrderCustomLocation> = [];

    @IsBoolean()
    @Expose()
    public potentialFraud?: boolean = false;

    @IsDefined()
    @Expose()
    public paymentMethod: PaymentMethod;

    @IsDefined()
    @Expose()
    public type: OrderType;

    @IsUUID("4")
    @Expose()
    public bundleId?: string;

    @IsDefined()
    @Expose()
    public assignationOffersCountPerVehicleType: number[];

    @IsEnum(AirportTransferDirection)
    @Expose()
    public airportTransferDirection?: AirportTransferDirection;
}
