// eslint-disable-next-line max-classes-per-file
import type { Location } from "@daytrip/legacy-models";
import type { MeetingPosition as MeetingPositionModel } from "@daytrip/legacy-models";
import type { OrderAutomaticEmail } from "@daytrip/legacy-models";
import type { Order } from "@daytrip/legacy-models";
import type { Position } from "@daytrip/legacy-models";
import { LocationAddressType } from "@legacy/domain/LocationAddressType";
import { LocationPointAddressType } from "@legacy/domain/LocationPointAddressType";
import { OrderAutomaticEmailType } from "@legacy/domain/OrderAutomaticEmailType";
import { OrderAutomaticEmailTypePair } from "@legacy/domain/OrderAutomaticEmailTypePair";
import { OrderStatus } from "@legacy/domain/OrderStatus";
import { PaymentMethod } from "@legacy/domain/PaymentMethod";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { RetrieveOrdersOptions } from "@legacy/options/RetrieveOrdersOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import { validateOrderAddress } from "@legacy/utils/validateOrderAddress";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { PageStore } from "../../../stores/PageStore";

import { OrderAddressCheckHolder } from "./OrderAddressCheckHolder";
import type { OrdersAddressCheckPageRouter } from "./OrdersAddressCheckPageRouter";

@autobind
export class OrdersAddressCheckPageStore extends PageStore<OrdersAddressCheckPageRouter, null> {
    @observable
    public orderHolders?: Array<OrderAddressCheckHolder>;

    @observable
    public ordersCount?: number = 0;

    @observable
    public isOrdersFetching: boolean = false;

    @observable
    public isOrdersFetchingFailed: boolean = false;

    @observable
    public isOrdersInvalid: boolean = false;

    @observable
    public isSaving: boolean = false;

    @observable
    public savedAndSent: boolean = false;

    @observable
    public users: Array<SimpleUser>;

    public pickupAddressEmailTypes: Array<OrderAutomaticEmailType> = [
        OrderAutomaticEmailType.PickupAddressAtAirport,
        OrderAutomaticEmailType.PickupAddressAtPort,
        OrderAutomaticEmailType.PickupAddressAtMeetingPosition,
        OrderAutomaticEmailType.PickupAddressIncomplete,
        OrderAutomaticEmailType.PickupAddressAtTrain,
    ];

    @action
    public clearSearch() {
        this.pageRouter.clearQuery();
        this.fetchContent();
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.orderHolders = undefined;

        const data = await this.ordersFetch();
        this.orderHolders = data.orderHolders;
    }

    @action
    public async ordersFetch(): Promise<{ orderHolders: Array<OrderAddressCheckHolder> }> {
        try {
            this.isOrdersFetching = true;

            const options: RetrieveOrdersOptions = {
                search: this.pageRouter.search,
                skip: this.pageRouter.skip,
                limit: this.pageRouter.limit,
                sortBy: this.pageRouter.sortBy as keyof Order,
                sortDirection: this.pageRouter.sortDirection,
                departureAtFrom: this.pageRouter.startDate
                    ? new Date(parseInt(this.pageRouter.startDate, 10))
                    : undefined,
                departureAtTo: this.pageRouter.endDate ? new Date(parseInt(this.pageRouter.endDate, 10)) : undefined,
                statusIn: [OrderStatus.Accepted, OrderStatus.Confirmed],
            };

            if (!this.pageRouter.isChecked) {
                options.isPickupAddressApproved = false;
                options.emailTypesNotSent = this.pickupAddressEmailTypes;
            }

            if (this.pageRouter.askedForCorrection) {
                options.isPickupAddressApproved = false;
                options.emailTypesSent = this.pickupAddressEmailTypes;
            }

            // Orders count is extremely slow and address validation is planned to be changed soon, we skip it for now
            this.ordersCount = 10000; // (await this.rpcClient.order.retrieveOrdersCount(options));

            const orders = await this.rpcClient.order.retrieveOrders(options);

            this.users = await this.rpcClient.user.retrieveSimpleUsers({
                userIds: orders.map((order) => order.userId),
            });

            const orderHolders: Array<OrderAddressCheckHolder> = [];

            if (orders && orders.length > 0) {
                const locationIds: Array<string> = [];

                orders.map((order) => {
                    if (locationIds.indexOf(order.originLocationId) < 0) {
                        locationIds.push(order.originLocationId);
                    }

                    if (locationIds.indexOf(order.destinationLocationId) < 0) {
                        locationIds.push(order.destinationLocationId);
                    }

                    return order;
                });

                const locations = await this.rpcClient.content.retrieveLocations({ ids: locationIds });

                this.meetingPositions = await this.rpcClient.content.retrieveMeetingPositions({ locationIds });

                await Promise.all(
                    orders.map(async (order) => {
                        let orderHolder = new OrderAddressCheckHolder();
                        orderHolder.order = order;

                        orderHolder = this.setEmailSentCheckboxDisabledProperties(orderHolder, order.automaticEmails);

                        orderHolder.originLocation = locations.find(
                            (location) => location._id == order.originLocationId,
                        ) as Location;
                        orderHolder.destinationLocation = locations.find(
                            (location) => location._id == order.destinationLocationId,
                        ) as Location;

                        orderHolder.isOk = order.isPickupAddressApproved;

                        const validator = validateOrderAddress(
                            order.pickupAddress || "",
                            order.meetingPositionId != undefined,
                        );

                        const airportCheckResult = validator.airportResult;
                        orderHolder.isAirport = airportCheckResult.isAirport && !orderHolder.isAirportEmailSent;

                        orderHolder.isBoat = validator.isBoat && !orderHolder.isBoatEmailSent;

                        orderHolder.isMeetingPosition =
                            validator.isMeetingPosition && !orderHolder.isMeetingPositionEmailSent;
                        orderHolder.isCash = order.paymentMethod == PaymentMethod.Cash;
                        orderHolder.isTrain = validator.isTrain && !orderHolder.isTrainEmailSent;

                        if (
                            !orderHolder.isAirport &&
                            !airportCheckResult.flightNumberMatch &&
                            !orderHolder.isBoat &&
                            !orderHolder.isTrain
                        ) {
                            orderHolder.isIncomplete = validator.isIncomplete && !orderHolder.isIncompleteEmailSent;
                        }

                        orderHolders.push(orderHolder);

                        return order;
                    }),
                );
            }

            return { orderHolders };
        } catch (e: any) {
            return Promise.reject(e);
        }
    }

    @action
    public async saveCheckedOrders(): Promise<void> {
        this.isSaving = true;

        const emailPairs: Array<OrderAutomaticEmailTypePair> = [];

        if (this.orderHolders == undefined) {
            return alert("No orders to save");
        }

        await Promise.all(
            this.orderHolders.map(async (holder) => {
                let needsUpdate = false;
                const pickUpAddress = PickUpAddress.getInstance(holder);

                if (holder.isOk) {
                    holder.order.isPickupAddressApproved = true;
                    needsUpdate = true;
                }

                if (pickUpAddress) {
                    holder.order.pickupAddressType = pickUpAddress.type;

                    if (!holder.isOk) {
                        emailPairs.push(pickUpAddress.emailTypePair);
                    }
                    needsUpdate = true;
                }

                if (needsUpdate) {
                    await this.rpcClient.order.updateOrderPickupType(
                        holder.order._id,
                        holder.order.pickupAddressType,
                        holder.order.isPickupAddressApproved,
                        holder.order.meetingPositionId,
                        holder.order.pickupAddress,
                    );
                    !isUndefinedOrNull(holder.order.version) ? holder.order.version++ : (holder.order.version = 0);
                }

                return holder;
            }),
        );

        try {
            if (emailPairs.length > 0) {
                await this.rpcClient.order.sendAutomaticEmails(emailPairs);
            }

            this.isSaving = false;
            this.savedAndSent = true;

            // display success status for 3 seconds
            setTimeout(() => {
                this.savedAndSent = false;
            }, 3000);

            await this.fetchData();
        } catch (e: any) {
            alert("Saving or sending emails failed.");

            this.isSaving = false;
            this.savedAndSent = false;
        }
    }

    public setEmailSentCheckboxDisabledProperties(
        holder: OrderAddressCheckHolder,
        automaticEmails: Array<OrderAutomaticEmail>,
    ): OrderAddressCheckHolder {
        if (!isUndefinedOrNull(automaticEmails) && automaticEmails.length > 0) {
            automaticEmails.forEach((email) => {
                if (email.type == OrderAutomaticEmailType.PickupAddressAtMeetingPosition) {
                    holder.isMeetingPositionEmailSent = true;
                }
                if (email.type == OrderAutomaticEmailType.PickupAddressAtAirport) {
                    holder.isAirportEmailSent = true;
                }
                if (email.type == OrderAutomaticEmailType.PickupAddressAtPort) {
                    holder.isBoatEmailSent = true;
                }
                if (email.type == OrderAutomaticEmailType.PickupAddressIncomplete) {
                    holder.isIncompleteEmailSent = true;
                }
                if (email.type == OrderAutomaticEmailType.PickupAddressAtTrain) {
                    holder.isTrainEmailSent = true;
                }
            });
        }

        return holder;
    }

    public isDataFetched(): this is OrdersAddressCheckPageStore & OrdersAddressCheckPageStoreDataFetched {
        return !isUndefinedOrNull(this.orderHolders) && !isUndefinedOrNull(this.users);
    }

    @observable
    public meetingPositionModalVisible: boolean = false;

    @observable
    public currentMeetingPositionOrderId: string | undefined;

    @observable
    public currentMeetingPositionOriginLocationId: string | undefined;

    @observable
    public meetingPositions: Array<MeetingPositionModel>;

    @action
    public showMeetingPositionModal(originLocationId: string, _pickupAddress: string, orderId: string) {
        this.meetingPositionModalVisible = true;
        this.currentMeetingPositionOrderId = orderId;
        this.currentMeetingPositionOriginLocationId = originLocationId;
    }

    @action
    public closeMeetingPositionModal(pickupType?: LocationPointAddressType) {
        const orderHolder = (this.orderHolders as Array<OrderAddressCheckHolder>).find(
            (h) => h.order._id == this.currentMeetingPositionOrderId,
        ) as OrderAddressCheckHolder;

        this.meetingPositionModalVisible = false;
        this.currentMeetingPositionOrderId = undefined;
        this.currentMeetingPositionOriginLocationId = undefined;

        // if it was meeting position for pick up type (train/port, airport) but no meeting position set -  check that pickup type
        if (orderHolder.order.meetingPositionId == undefined && pickupType != undefined) {
            switch (pickupType) {
                case LocationPointAddressType.Airport:
                    orderHolder.order.pickupAddressType = LocationAddressType.Airport;
                    orderHolder.isAirport = true;
                case LocationPointAddressType.Port:
                    orderHolder.order.pickupAddressType = LocationAddressType.Port;
                    orderHolder.isBoat = true;
                case LocationPointAddressType.Train:
                    orderHolder.order.pickupAddressType = LocationAddressType.Train;
                    orderHolder.isTrain = true;
            }

            orderHolder.isMeetingPosition = false;
            orderHolder.isMeetingPositionResend = false;
        }
    }

    @action
    public setMeetingPosition(meetingPositionId: string | undefined, orderId: string) {
        if (this.orderHolders != undefined) {
            const orderHolder = this.orderHolders.find((h) => h.order._id == orderId) as OrderAddressCheckHolder;
            orderHolder.order.meetingPositionId = meetingPositionId;

            if (meetingPositionId == undefined) {
                orderHolder.order.pickupAddress = orderHolder.order.pickupAddress
                    .split("(Meeting point")[0]
                    .split("Meeting point")[0];
            } else {
                const meetingPosition = this.meetingPositions.find(
                    (m) => m._id == meetingPositionId,
                ) as MeetingPositionModel;

                orderHolder.order.pickupAddress = orderHolder.order.pickupAddress
                    .split("(Meeting point")[0]
                    .split("Meeting point")[0];

                const meetingPositionPickupAddress =
                    orderHolder.order.pickupAddress.trim() == ""
                        ? ` Meeting point: ${meetingPosition.name}`
                        : ` (Meeting point: ${meetingPosition.name})`;
                orderHolder.order.pickupAddress = orderHolder.order.pickupAddress.concat(meetingPositionPickupAddress);
            }
        }
    }

    @action
    public getPickupAddressForMeetingPositionsModal() {
        const orderHolder = (this.orderHolders as Array<OrderAddressCheckHolder>).find(
            (h) => h.order._id == this.currentMeetingPositionOrderId,
        ) as OrderAddressCheckHolder;

        if (this.currentPickupAddress == undefined) {
            this.currentPickupAddress =
                orderHolder.order.pickupAddress == undefined || orderHolder.order.pickupAddress == ""
                    ? undefined
                    : `${orderHolder.order.pickupAddress}, ${orderHolder.originLocation.name}`;
        }

        if (this.currentPickupAddressPosition == undefined) {
            if (orderHolder.order.meetingPositionId != undefined) {
                this.currentPickupAddressPosition = (
                    this.meetingPositions.find(
                        (mp) => mp._id == orderHolder.order.meetingPositionId,
                    ) as MeetingPositionModel
                ).position;
            } else {
                this.locationCalculateAddress(this.currentPickupAddress);
            }
        }

        return this.currentPickupAddress;
    }

    @observable
    public currentPickupAddressPosition: Position | undefined;

    @observable
    public currentPickupAddress: string | undefined;

    public async locationCalculateAddress(address: string | undefined) {
        if (address == undefined || address == "") {
            return undefined;
        }

        try {
            this.currentPickupAddressPosition = (await this.rpcClient.maps.fetchPositionByAddress(address)).position;
            return;
        } catch (e: any) {
            alert("Couldn't get pick up address position from Google.");
            return undefined;
        }
    }

    public getCurrentLocationPosition() {
        if (!this.orderHolders) {
            return undefined;
        }
        return (
            this.orderHolders.find(
                (oh) => oh.originLocation._id == this.currentMeetingPositionOriginLocationId,
            ) as OrderAddressCheckHolder
        ).originLocation.position;
    }
}

export interface OrdersAddressCheckPageStoreDataFetched {
    ordersCount: number;
}

// todo move in more appropriate place
class PickUpAddress {
    public type: number;

    public emailType: number;

    protected holder: OrderAddressCheckHolder;

    public constructor(holder?: OrderAddressCheckHolder) {
        this.setHolder(holder);
    }

    public setHolder(holder?: OrderAddressCheckHolder) {
        if (holder) {
            this.holder = holder;
        }
    }

    public get emailTypePair(): OrderAutomaticEmailTypePair {
        const emailPair = new OrderAutomaticEmailTypePair();
        emailPair.orderId = this.holder.order._id;
        emailPair.type = this.emailType;

        return emailPair;
    }

    public static getInstance(holder: OrderAddressCheckHolder): PickUpAddress | undefined {
        let pickUpAddress: PickUpAddress | undefined;

        [
            { class: AirportPosition, fields: ["isAirport", "isAirportResend"] },
            { class: BoatPosition, fields: ["isBoat", "isBoatResend"] },
            { class: MeetingPosition, fields: ["isMeetingPosition", "isMeetingPositionResend"] },
            { class: IncompletePosition, fields: ["isIncomplete", "isIncompleteResend"] },
            { class: TrainPosition, fields: ["isTrain", "isTrainResend"] },
        ].find((element) => {
            if (holder[element.fields[0]] || holder[element.fields[1]]) {
                pickUpAddress = new element.class();
                return true;
            }

            return false;
        });

        if (pickUpAddress) {
            pickUpAddress.setHolder(holder);
        }

        return pickUpAddress;
    }
}

class AirportPosition extends PickUpAddress {
    public constructor(holder?: OrderAddressCheckHolder) {
        super(holder);
        this.type = LocationAddressType.Airport;
        this.emailType = OrderAutomaticEmailType.PickupAddressAtAirport;
    }
}

class BoatPosition extends PickUpAddress {
    public constructor(holder?: OrderAddressCheckHolder) {
        super(holder);
        this.type = LocationAddressType.Port;
        this.emailType = OrderAutomaticEmailType.PickupAddressAtPort;
    }
}

class MeetingPosition extends PickUpAddress {
    public constructor(holder?: OrderAddressCheckHolder) {
        super(holder);
        this.type = LocationAddressType.MeetingPosition;
        this.emailType = OrderAutomaticEmailType.PickupAddressAtMeetingPosition;
    }
}

class IncompletePosition extends PickUpAddress {
    public constructor(holder?: OrderAddressCheckHolder) {
        super(holder);
        this.emailType = OrderAutomaticEmailType.PickupAddressIncomplete;
    }
}

class TrainPosition extends PickUpAddress {
    public constructor(holder?: OrderAddressCheckHolder) {
        super(holder);
        this.type = LocationAddressType.Train;
        this.emailType = OrderAutomaticEmailType.PickupAddressAtTrain;
    }
}
