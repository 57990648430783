/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
import type { Location } from "@daytrip/legacy-models";
import type { SimpleTravelData } from "@legacy/domain/SimpleTravelData";
import type { Route } from "@legacy/models/Route";
import { RouteLandingContent } from "@legacy/models/RouteLandingContent";
import autobind from "autobind-decorator";
import { action, toJS, observable } from "mobx";
import { v4 as uuid } from "uuid";

import { IMGIX_UPLOAD_URL, IMGIX_SOURCE_ID, IMGIX_API_KEY, IMGIX_ASSETS_URL } from "../../../config.management";
import type { StoreManager } from "../../../container";
import { container, stores } from "../../../container";
import { PageStore } from "../../../stores/PageStore";
import { extractDirectionsFromRoute } from "../Route.utils";
import type { RoutePageRouter } from "../RoutePageRouter";
import type { RoutePageStore } from "../RoutePageStore";
@autobind
export class LandingContentPageStore extends PageStore<RoutePageRouter, {}> {
    protected routePageStore: RoutePageStore = container.get<StoreManager<RoutePageStore>>(stores.routePage).getStore();

    @observable
    public content: string = "";

    @observable
    public isPublished: boolean = false;

    @observable
    public isContentFetched: boolean = false;

    @observable
    public routeId: string | undefined;

    @observable
    public machineName: string | undefined;

    @observable
    public route: Route | undefined;

    @observable
    public originLocation: Location | undefined;

    @observable
    public destinationLocation: Location | undefined;

    @observable
    public travelData: SimpleTravelData | undefined;

    @observable
    public isRouteFetched: boolean = false;

    @observable
    public isImageUploading: boolean = false;

    @observable
    public landingContent: RouteLandingContent;

    @action
    public async updateMachineName(machineName: string) {
        this.machineName = machineName;
        this.isContentFetched = false;
        await this.readContentFromMdx();
        this.resetLandingContent();
    }

    @action
    public async saveLandingContent() {
        if (!this.route || !this.machineName) {
            return;
        }

        const editedRoute = toJS(this.route);
        editedRoute.landingContent = Object.assign(editedRoute.landingContent ?? {}, {
            [this.machineName]: this.landingContent,
        });
        await this.rpcClient.content.updateRoute(editedRoute._id, editedRoute);
        await this.onFetchData(/* resetDirections= */ false);
    }

    @action resetLandingContent() {
        if (!this.machineName) {
            return;
        }

        this.landingContent = this.route?.landingContent?.[this.machineName] ?? {};
    }

    @action
    public async onFetchData(resetDirections = true) {
        const { routeId } = this.pageRouter;
        this.routeId = routeId;
        await this.fetchRoute();
        if (resetDirections) {
            const { firstDirection } = extractDirectionsFromRoute(this.route);
            this.updateMachineName(firstDirection);
        }
    }

    public isDataFetched(): this is LandingContentPageStore & LandingContentPageStoreDataFetched {
        return Boolean(this.isRouteFetched && this.isContentFetched);
    }

    @action
    public async fetchRoute() {
        if (!this.routeId) {
            return;
        }
        try {
            this.route = await this.rpcClient.content.retrieveRoute(this.routeId);
            const [originLocation, destinationLocation, travelData] = await Promise.all([
                this.rpcClient.content.retrieveLocation(this.route?.originLocationId),
                this.rpcClient.content.retrieveLocation(this.route?.destinationLocationId),
                this.rpcClient.content.calculateSimpleTravelData([
                    this.route.originLocationId,
                    this.route.destinationLocationId,
                ]),
            ]);
            this.originLocation = originLocation;
            this.destinationLocation = destinationLocation;
            this.travelData = travelData;
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
        this.isRouteFetched = true;
    }

    @action
    public async readContentFromMdx() {
        if (!this.machineName) {
            return;
        }
        try {
            const { content, isPublished } = await this.rpcClient.contentLanding.getContentFromMdxFile({
                fileName: this.machineName,
            });
            this.content = content;
            this.isPublished = isPublished;
        } catch (error: any) {
            // file does not exist, continue with blank content
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
        this.isContentFetched = true;
    }

    @action
    public async saveDraftMdx() {
        if (!this.machineName) {
            return;
        }

        try {
            await this.rpcClient.contentLanding.saveContentFile({
                fileName: `${this.machineName}-draft`,
                content: this.content,
            });
            this.isPublished = false;
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            return;
        }
    }

    @action
    public async removeMdx() {
        if (!this.machineName) {
            return;
        }
        try {
            await this.rpcClient.contentLanding.removeContentFile({ fileName: this.machineName });
            this.content = "";
            this.isPublished = true;
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            return;
        }
    }

    @action
    public async publishLandingPage() {
        if (!this.machineName) {
            return;
        }

        try {
            await this.rpcClient.contentLanding.saveContentFile({ fileName: this.machineName, content: this.content });
            this.isPublished = true;
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            return;
        }
    }

    @action
    public async uploadImageToImgix(file: File) {
        this.isImageUploading = true;
        const extension = file.name.split(".").pop();
        const filePathName = `${uuid()}.${extension}`;
        const endpoint = `sources/${IMGIX_SOURCE_ID}/upload/${filePathName}`;
        let assetUrl = "";
        const saveButton = document.querySelector('[title="Save"]');
        const cancelButton = document.querySelector('[title="Cancel"]');
        saveButton?.setAttribute("disabled", "true");
        cancelButton?.setAttribute("disabled", "true");
        try {
            await fetch(`${IMGIX_UPLOAD_URL}/${endpoint}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${IMGIX_API_KEY}`,
                },
                body: file,
            });
            assetUrl = `${IMGIX_ASSETS_URL}/${filePathName}?w=700&h=300&fit=crop`;
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
        saveButton?.setAttribute("disabled", "false");
        cancelButton?.setAttribute("disabled", "false");
        this.isImageUploading = false;
        return Promise.resolve(assetUrl);
    }

    @action
    public async updateContent(inputContent: string) {
        this.content = inputContent;
    }
}

export interface LandingContentPageStoreDataFetched {
    route: Route;
    content: string;
}
