import { API_HOST, API_PORT, IS_LOCAL, IS_PRODUCTION } from "@daytrip/legacy-config";

export const FRESH_HOST = process.env.DAYTRIP_FRESH_HOST!;
export const FRESH_URL = process.env.DAYTRIP_FRESH_URL!;
export const LEGACY_RPC_URL = process.env.DAYTRIP_LEGACY_RPC_URL!;
export const API_EXPORTS_ENDPOINT = process.env.DAYTRIP_API_EXPORTS_ENDPOINT!;

export const ENVIRONMENT = global.BUILD_ENV!; // TODO:
export const REVISION = global.REVISION!;
export const VERSION = global.VERSION!;
export const BUILD_DATE = global.BUILD_DATE!;

export const COOKIE_PREFIX = IS_PRODUCTION ? "" : `${ENVIRONMENT}-`;

export const BASE_IMAGE_URL = "https://daytrip.imgix.net"; // Note: This is also duplicated in the legacy config
export const REACT_ROOT_ELEMENT_ID = "app";

export const FAVICONS_IMAGE_URL = `${BASE_IMAGE_URL}/favicons2`;
export const IMAGE_URL = `${BASE_IMAGE_URL}/management`;
export const IMAGE_MAP_MARKERS_URL = `${IMAGE_URL}/map_markers`;

export const PAGE_TITLE_SUFFIX = "| Daytrip management";

export const PAGINATION_DEFAULT_COUNT = 15;
export const ORDERS_ADDRESS_CHECK_PAGINATION_DEFAULT_COUNT = 10;

export const localStorageKeys = {
    notifications: "myNotifications",
};

// NOTE: this is duplicated in the legacy config
export const cookies = {
    authentication: `${COOKIE_PREFIX}managementAuthenticationToken`,
    refresh: `${COOKIE_PREFIX}managementRefreshToken`,
    isDriversAppAlertHidden: `${COOKIE_PREFIX}isDriversAppAlertHidden`,
};
export const FB_DRIVERS_MESSENGER_USERNAME = "daytripdrivers"; // Note: duplicated in config.ts

export const MANAGEMENT_SENTRY_DSN = process.env.DAYTRIP_MANAGEMENT_SENTRY_DSN;
export const MANAGEMENT_TRACES_SENTRY_SAMPLE_RATE = parseFloat(
    process.env.DAYTRIP_MANAGEMENT_TRACES_SENTRY_SAMPLE_RATE || "0.01",
);
export const GOOGLE_MAPS_API_KEY = process.env.DAYTRIP_GOOGLE_MAPS_API_KEY; // Note: duplicated in config.ts
// Note: also present int `config.ts`
export const AUTH_JWT_EXPIRES_IN = process.env.DAYTRIP_AUTH_JWT_EXPIRES_IN || "25m";
export const AUTH_REFRESH_JWT_EXPIRES_IN = process.env.DAYTRIP_AUTH_REFRESH_JWT_EXPIRES_IN || "96h";
// ===================

export const API_MNG_GQL_URL = process.env.DAYTRIP_MNG_GQL_URL!; // TODO:

// TODO: figure out if this is really needed
export const SITE_BASE_URL = process.env.DAYTRIP_SITE_BASE_URL; // Note: duplicated in config.ts

// apple maps
export const APPLE_MAPS_TOKEN = process.env.DAYTRIP_APPLE_MAPS_TOKEN;

// TODO: remove the IS_LOCAL conditional;
export const VERSION_URL = `${`${API_HOST}${IS_LOCAL ? `:${API_PORT}` : ""}`}/version`;
export const EMAIL_ATTEMPTS_THRESHOLD = 5; // Note: duplicated in config.ts

export const MANGOPAY_DASHBOARD_URL = `https://dashboard${!IS_PRODUCTION ? ".sandbox" : ""}.mangopay.com`;
export const CHECKOUT_DASHBOARD_URL = `https://${!IS_PRODUCTION ? "sandbox" : "hub"}.checkout.com`;
export const STRIPE_DASHBOARD_URL = `https://dashboard.stripe.com${!IS_PRODUCTION ? "/test" : ""}`;

// TODO: remove unused - https://app.shortcut.com/daytrip/story/31987/remove-unused-feature-toggles
export const FEATURE_FRESH_TRANSPORTATION_OFFERS = process.env.DAYTRIP_FEATURE_FRESH_TRANSPORTATION_OFFERS === "true";

export const IMGIX_UPLOAD_URL = process.env.DAYTRIP_IMGIX_UPLOAD_URL;
export const IMGIX_ASSETS_URL = process.env.DAYTRIP_IMGIX_ASSETS_URL;
export const IMGIX_API_KEY = process.env.DAYTRIP_IMGIX_API_KEY;
export const IMGIX_SOURCE_ID = process.env.DAYTRIP_IMGIX_SOURCE_ID;

export const DAYTRIP_MAILGUN_DOMAIN = process.env.DAYTRIP_MAILGUN_DOMAIN;
