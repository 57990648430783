/* eslint-disable no-alert,no-param-reassign */
import {
    API_OPEN_DRIVER_DOCUMENT_URL,
    API_UPLOAD_PROFILE_PHOTO_URL,
    MAX_FILE_UPLOAD_SIZE_IN_B,
    US_COUNTRY_ID,
} from "@daytrip/legacy-config";
import type { BillingInformation } from "@legacy/domain/BillingInformation";
import { DocumentSubject } from "@legacy/domain/DocumentSubject";
import { DynamicDocumentType } from "@legacy/domain/DocumentTypes";
import type { FilePath } from "@legacy/domain/FilePath";
import { PayoutType } from "@legacy/domain/PayoutType";
import { ProfilePhotoStatus } from "@legacy/domain/ProfilePhotoStatus";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import type { AutomaticEmailAttempt } from "@legacy/models/AutomaticEmailAttempt";
import { Document } from "@legacy/models/Document";
import { DocumentType } from "@legacy/models/DocumentType";
import { Driver } from "@legacy/models/Driver";
import type { ProfilePhoto } from "@legacy/models/ProfilePhoto";
import type { Region } from "@legacy/models/Region";
import { User } from "@legacy/models/User";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, observable, reaction, toJS } from "mobx";

import { routes } from "../../container";
import { globalManagementLogger } from "../../global-logger";
import { DocumentOperator } from "../../operators/DocumentOperator";
import { DocumentTypeOperator } from "../../operators/DocumentTypeOperator";
import { DriverOperator } from "../../operators/DriverOperator";
import { PageStore } from "../../stores/PageStore";
import { FetchDataStatus } from "../../utils/FetchDataStatus";
import { throwIfInvalidUserPhoneNumber } from "../../utils/validateUserPhoneNumber";

import type { DriverProfileRouter } from "./DriverProfileRouter";

@autobind
export class DriverProfileStore extends PageStore<DriverProfileRouter, {}> {
    @observable
    public driverOperator: DriverOperator;

    public canCreateHyperwalletAccount: boolean;

    public canCreateStripeDriverAccount: boolean;

    @observable
    public countries?: Array<SimpleCountry>;

    @observable
    public regions?: Array<Region>;

    @observable
    public documentTypeOperators?: Array<DocumentTypeOperator>;

    public isNewDriver = false;

    @observable
    public existingUser?: User;

    @observable
    public hiredByUser?: User;

    @observable
    public driversCompanyName?: string = "";

    @observable
    public automaticEmailAttempts: Array<AutomaticEmailAttempt> = [];

    @computed
    public get isCompanyDriver(): boolean {
        return !!this.driverOperator.driver.driversCompanyUserId;
    }

    @action
    public initDriverOperator(): void {
        this.driverOperator = new DriverOperator({
            modelConstructor: User,
            data: {
                simpleCountries: this.countries,
                managers: [],
            },
            modules: null,
            onFetchData: async (operator: DriverOperator) => {
                let { userId } = this.pageRouter;
                const { userEmail } = this.pageRouter;
                const { userJWT, hasInternalManagementPermission, isRegionalManager } = this.authenticationStore;
                const currentUserId = userJWT?.userId;

                if (!hasInternalManagementPermission && !isRegionalManager) {
                    if (userId && userId !== currentUserId) {
                        return FetchDataStatus.Unauthorized;
                    }
                    userId = currentUserId;
                }

                try {
                    if (userId) {
                        operator.model = plainToClass(User, await this.rpcClient.user.retrieveUser(userId));
                    } else if (userEmail) {
                        operator.model = plainToClass(User, await this.rpcClient.user.retrieveUserByEmail(userEmail));
                    } else {
                        operator.model.driver = new Driver();

                        if (hasInternalManagementPermission || isRegionalManager) {
                            operator.model.driver.hiredByUserId = currentUserId;
                        }

                        this.isNewDriver = true;
                        operator.edit(() => {});
                    }

                    if (hasInternalManagementPermission || isRegionalManager) {
                        operator.data.managers = await this.rpcClient.user.getSimpleRegionalManagersByCountryId(
                            operator.model.countryId,
                        );
                    }

                    if (operator.model.driver?.driversCompanyUserId) {
                        this.driversCompanyName = await this.rpcClient.driversCompany.retrieveDriversCompanyName(
                            operator.model.driver.driversCompanyUserId,
                        );
                    }

                    operator.isPremium = !!operator.model.driver?.premiumFrom;
                    operator.isSuitableForLuxury = !!operator.model.driver?.isSuitableForLuxury;
                    operator.isSuitableForPool = !!operator.model.driver?.isSuitableForPool;
                    this.canCreateHyperwalletAccount = operator.model.hyperwalletInformation == null;
                    this.canCreateStripeDriverAccount = operator.model.stripeInformation == null;
                } catch (e: any) {
                    return FetchDataStatus.NotFound;
                }

                return FetchDataStatus.Success;
            },
            isDataFetchedCondition: (operator: DriverOperator) =>
                !isUndefinedOrNull(operator.model) && !isUndefinedOrNull(operator.data.simpleCountries),
            beforeSave: async (user: User) => {
                const shouldValidatePayoutType = user.driver && !user.driver.driversCompanyUserId;

                if (shouldValidatePayoutType && this.driverOperator.m.payoutType === undefined) {
                    alert("Payout type is required");
                    throw new Error("Payout type is required");
                }
                if (user.payoutType === PayoutType.Hyperwallet && this.canCreateHyperwalletAccount) {
                    if (!user.firstName || !user.lastName || !user.email || !user.countryId || !user.phoneNumber) {
                        alert(
                            "To add Hyperwallet as Payout Type you need to fill the fields: First name, Last name, Email, Country, Phone Number",
                        );
                        throw new Error("Missing fields for hyperwallet account creation");
                    }
                }

                if (user.payoutType === PayoutType.Stripe && this.canCreateStripeDriverAccount) {
                    if (!user.email || !user.countryId) {
                        alert("To add Stripe as Payout Type you need to fill the fields: Email and Country");
                        throw new Error("Missing fields for stripe connected account creation");
                    }
                }

                throwIfInvalidUserPhoneNumber(user.phoneNumber);
            },
            onSave: async (user: User & { driver: Driver }) => {
                if (!this.driverOperator.isAddressValid) {
                    throw new Error("Provided hometown is not valid");
                }
                if (!this.isNewDriver) {
                    const oldUser = await this.rpcClient.user.retrieveUser(user._id);

                    if (oldUser.payoutType !== user.payoutType && user.billingInformation.length) {
                        const activeBillingInformationIndex = user.billingInformation.findIndex(
                            (billingInformation: BillingInformation) => billingInformation.isActive,
                        );
                        const activeBillingInformation = user.billingInformation[activeBillingInformationIndex];
                        if (activeBillingInformation && activeBillingInformation.payoutType !== user.payoutType) {
                            if (isUndefinedOrNull(user.payoutType)) {
                                throw new Error("Payout type is missing");
                            }
                            user.billingInformation[activeBillingInformationIndex].payoutType = user.payoutType;
                        }
                    }

                    if (this.authenticationStore.isDriver) {
                        await this.rpcClient.driver.updateUser(user._id, user, {
                            validationGroups: ["profile", "driver"],
                        });
                    } else {
                        await this.rpcClient.user.updateUser(user._id, user, {
                            validationGroups: ["profile", "driver"],
                        });
                    }

                    this.driverOperator.edit((u) => (!isUndefinedOrNull(u.version) ? u.version++ : (u.version = 0)));

                    if (user.payoutType === PayoutType.Hyperwallet && this.canCreateHyperwalletAccount) {
                        await this.createHyperwalletUser(user);
                    }

                    if (user.payoutType === PayoutType.Stripe && this.canCreateStripeDriverAccount) {
                        await this.createStripeConnectedAccount(user);
                    }

                    if (user.hyperwalletInformation?.token && oldUser.email !== user.email) {
                        // update hyperwallet user email
                        await this.rpcClient.hyperwallet.updateHyperWalletUser(user._id);
                    }
                } else {
                    try {
                        user._id = await this.rpcClient.user.createUser(user);
                    } catch (err: any) {
                        let statusCode;
                        try {
                            const errorObj = JSON.parse(err.message);
                            statusCode = errorObj.statusCode;
                        } catch (e) {
                            throw err;
                        }

                        if (statusCode === 403 && this.authenticationStore.isRegionalManager) {
                            throw new Error(
                                "You do not have the permissions to add a driver within this country. Please contact your manager to allow access. Also, make sure to log out and back in again after your new regional permissions have been added",
                            );
                        }
                        throw err;
                    }

                    if (user.payoutType === PayoutType.Hyperwallet) {
                        await this.createHyperwalletUser(user);
                    }

                    if (user.payoutType === PayoutType.Stripe) {
                        await this.createStripeConnectedAccount(user);
                    }

                    this.pageRouter.openCreatedUser(user._id);
                    this.isNewDriver = false;
                }
            },
            validateOptions: { skipMissingProperties: true, groups: ["profile", "driver"] },
        });
    }

    private async createHyperwalletUser(user: User) {
        try {
            const hyperwalletResponse = await this.rpcClient.hyperwallet.createHyperWalletUser(user._id);
            if (hyperwalletResponse) {
                this.driverOperator.edit((model) => {
                    model.hyperwalletInformation = hyperwalletResponse.hyperwalletInformation;
                });
                this.canCreateHyperwalletAccount = false;
            }
        } catch (error) {
            // eslint-disable-next-line no-alert
            alert(`Hyperwallet user cannot be created. Reason: ${error}`);
        }
    }

    private async createStripeConnectedAccount(user: User) {
        try {
            const response = await this.rpcClient.stripe.createStripeDriverAccount(user._id);
            if (response) {
                this.driverOperator.edit((model) => {
                    model.stripeInformation = response.stripeInformation;
                });
                this.canCreateStripeDriverAccount = false;
            }
        } catch (error) {
            // eslint-disable-next-line no-alert
            alert(`Stripe connected account cannot be created. Reason: ${error}`);
        }
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        await this.loadSimpleCountries();

        if (isUndefinedOrNull(this.driverOperator)) {
            this.initDriverOperator();
        }

        await Promise.all([this.loadRegions(), this.driverOperator.fetchData()]);

        if (!this.driverOperator.model.driver && this.driverOperator.model.driversCompany) {
            this.pageRouter.routerStore.replace({
                pathname: routes.driversCompanyProfile,
                search: `?userId=${this.driverOperator.model._id}`,
            });
            return;
        }

        if (
            !isUndefinedOrNull(this.driverOperator.m?.profilePhoto) &&
            this.driverOperator.m.profilePhoto.status === ProfilePhotoStatus.Declined
        ) {
            this.profilePhotoDeclinationReason = this.driverOperator.m.profilePhoto.declinationReason || "";
        }

        await Promise.all([this.fetchDocuments(), this.loadHiredByUser()]);
    }

    private async loadSimpleCountries() {
        const countries = await this.rpcClient.content.retrieveSimpleCountries({});
        this.countries = countries.sort((a, b) => a.englishName.localeCompare(b.englishName));
    }

    private async loadRegions() {
        const regions = await this.rpcClient.content.retrieveRegions({ countryIds: [US_COUNTRY_ID] });
        this.regions = regions.sort((a, b) => a.englishName.localeCompare(b.englishName));
    }

    private async loadHiredByUser() {
        if (this.driverOperator.driver.hiredByUserId && this.authenticationStore.hasInternalManagementPermission) {
            try {
                this.hiredByUser = await this.rpcClient.user.retrieveUserObjectById(
                    this.driverOperator.driver.hiredByUserId,
                );
            } catch (error) {
                globalManagementLogger.error(
                    `Unable to find hiredByUser with id ${this.driverOperator.driver.hiredByUserId}`,
                );
                this.hiredByUser = undefined;
            }
        }
    }

    @action
    public async deleteUser() {
        try {
            const userId = this.driverOperator.m._id;
            await this.rpcClient.driver.deleteUser(userId);
            this.pageRouter.openUsersAfterDeletion();
        } catch (e: any) {
            alert("Something went wrong during deletion.");
            globalManagementLogger.error(e);
        }
    }

    public isDataFetched(): this is DriverProfileStore & DriverProfileStoreDataFetched {
        return (
            this.countries !== undefined &&
            this.regions !== undefined &&
            this.documentTypeOperators !== undefined &&
            this.driverOperator.isDataFetched() &&
            this.driverOperator.driver !== undefined
        );
    }

    public countryIdReaction = reaction(
        () => this.driverOperator?.m.countryId,
        (countryId) => {
            if (countryId) {
                this.fetchDocuments();
            }
        },
    );

    private async fetchDynamicFormDocuments() {
        const ids = this.driverOperator.m.driver?.documents.map((doc) => doc.documentTypeId);

        if (!ids || !ids.length) {
            return [];
        }

        const documentTypesForDriver = plainToClass(
            DocumentType,
            await this.rpcClient.driver.retrieveDocumentTypes({ ids }),
        );

        return documentTypesForDriver.filter(
            (docType) => docType.dynamicDocumentType === DynamicDocumentType.COOPERATION_AGREEMENT,
        );
    }

    private async fetchRegularDocuments() {
        if (!this.driverOperator.m?.countryId || this.driverOperator.driver === undefined) {
            return [];
        } else {
            const subject = this.driverOperator.driver.driversCompanyUserId
                ? DocumentSubject.CompanyDriver
                : DocumentSubject.Driver;
            const documentTypesForDriver = plainToClass(
                DocumentType,
                await this.rpcClient.driver.retrieveDocumentTypes({
                    countryIds: [this.driverOperator.m.countryId],
                    subject,
                }),
            );

            return documentTypesForDriver;
        }
    }

    @action
    public async fetchDocuments() {
        const [regularDocuments, dynamicFormDocuments] = await Promise.all([
            this.fetchRegularDocuments(),
            this.fetchDynamicFormDocuments(),
        ]);

        const allDocuments = [...regularDocuments, ...dynamicFormDocuments];

        this.documentTypeOperators = this.mapDocTypeToOperator(allDocuments);
    }

    private mapDocTypeToOperator(documentTypesForDriver: DocumentType[]): DocumentTypeOperator[] {
        return documentTypesForDriver.map((documentType) => {
            let document = this.driverOperator.driver.documents.find(
                (d) => d.documentTypeId === documentType._id && isUndefinedOrNull(d.deletedAt),
            );

            if (documentType.dynamicDocumentType === DynamicDocumentType.COOPERATION_AGREEMENT) {
                const activeBillingInfo = this.driverOperator.m.billingInformation.find((bi) => bi.isActive);

                if (activeBillingInfo) {
                    document = this.driverOperator.driver.documents.find(
                        (doc) => doc._id === activeBillingInfo.documentId,
                    );
                } else {
                    const allCAs = this.driverOperator.driver.documents.filter(
                        (doc) => doc.documentTypeId === documentType._id,
                    );
                    // if driver has no Active BI, show latest CA
                    document = allCAs.at(-1);
                }
            }

            const dto = new DocumentTypeOperator({
                modelConstructor: DocumentType,
                model: documentType,
                documentOperator:
                    document &&
                    new DocumentOperator({
                        modelConstructor: Document,
                        model: document,
                        data: null,
                        modules: null,
                    }),
                data: null,
                modules: null,
                userId: this.driverOperator.m._id,
                onDriverDocumentUploaded: async () => {
                    this.driverOperator.cancelEdit();
                    await this.fetchData();
                    await this.fetchDocuments();
                },
            });
            return dto;
        });
    }
    // upload process

    @observable
    public isProfilePhotoModalOpened: boolean = false;

    @action
    public toggleProfilePhotoModal(): void {
        this.isProfilePhotoModalOpened = !this.isProfilePhotoModalOpened;
    }

    @observable
    public isProfilePhotoUploading: boolean = false;

    @observable
    public isImageCropperVisible: boolean = false;

    @action
    public toggleImageCropperView(isVisible: boolean): void {
        this.isImageCropperVisible = isVisible;
    }

    @observable
    public originalImageFile: File | null = null;

    @action
    public setOriginalImageFile(file: File): void {
        this.originalImageFile = file;
    }

    @action
    public async startProfilePhotoUpload(file: Blob) {
        this.driverProfileImageFormData = new FormData();

        if (isUndefinedOrNull(this.driverOperator.m)) {
            alert("Please fill all required fields and save new user. Then you can upload profile image.");
            return;
        }

        const userId = this.driverOperator.m._id;
        const { authenticationToken } = this.authenticationStore;
        this.driverProfileImageFormData.append(
            "profileImage",
            new File([file], "profileImage.jpg", { type: "image/jpeg" }),
        );
        this.driverProfileImageFormData.append("userId", userId);

        if (file.size >= MAX_FILE_UPLOAD_SIZE_IN_B) {
            alert("The file can\u0027t be larger than 5 MB.");
            this.isProfilePhotoUploading = false;
            return;
        }

        this.isProfilePhotoUploading = true;
        await this.rpcClient.authentication.isUserAuthenticated();
        const request = new XMLHttpRequest();
        request.open("POST", API_UPLOAD_PROFILE_PHOTO_URL, true);
        request.onload = () => {
            if (request.status === 200 || request.status === 201) {
                this.driverOperator.model.profilePhoto = JSON.parse(request.responseText) as ProfilePhoto;

                this.isProfilePhotoUploading = false;
                this.isProfilePhotoModalOpened = false;

                this.refreshProfilePhoto();
            } else {
                alert(
                    "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
                );
                this.isProfilePhotoUploading = false;
            }
            this.profilePhotoDeclinationReason = "";
        };
        request.setRequestHeader("Authorization", `Bearer ${authenticationToken}`);
        request.send(this.driverProfileImageFormData);
    }

    @observable
    public profilePhotoUploadingAttempt = 0;

    @action
    public async refreshProfilePhoto() {
        await this.driverOperator.fetchData();
        this.profilePhotoUploadingAttempt += 1;
    }

    @action
    public async approveDriverProfilePhoto() {
        if (isUndefinedOrNull(this.driverOperator.m) && isUndefinedOrNull(this.pageRouter.userId)) {
            alert("There is no user loaded.");
            return;
        }

        const userId = isUndefinedOrNull(this.driverOperator.m) ? this.pageRouter.userId : this.driverOperator.m._id;

        try {
            await this.rpcClient.driver.approveProfilePhoto(userId as string);
            await this.driverOperator.fetchData();
            this.profilePhotoDeclinationReason = "";
        } catch (e: any) {
            alert("Oh, something got wrong. :(");
        }
    }

    @observable
    public profilePhotoDeclinationReason: string;

    @action
    public setProfilePhotoDeclinationReason(declinationReason: string): void {
        this.profilePhotoDeclinationReason = declinationReason;
    }

    @action
    public async declineDriverProfilePhoto(): Promise<void> {
        if (isUndefinedOrNull(this.driverOperator.m) && !this.pageRouter.userId) {
            alert("There is no user loaded.");
            return;
        }

        const userId = isUndefinedOrNull(this.driverOperator.m) ? this.pageRouter.userId : this.driverOperator.m._id;

        if (!this.profilePhotoDeclinationReason) {
            alert("Please write some declination reason.");
            return;
        }

        try {
            await this.rpcClient.driver.declineProfilePhoto(userId as string, this.profilePhotoDeclinationReason);
            await this.driverOperator.fetchData();
        } catch (e: any) {
            alert("Oh, something got wrong. :(");
        }
    }

    @observable
    public lightboxDocuments?: Array<FilePath>;

    @observable
    public lightboxInitialIndex: number = 0;

    @observable
    public lightboxDescription: string | undefined;

    @action
    public async revealLightbox(fileIds: Array<string>, title: string, index: number = 0) {
        this.lightboxInitialIndex = index;
        this.lightboxDocuments = await this.rpcClient.driver.retrieveFilesPaths(fileIds, API_OPEN_DRIVER_DOCUMENT_URL);
        this.lightboxDocuments = this.lightboxDocuments.sort((a, b) => a.url.localeCompare(b.url));
        this.lightboxDescription = title;
    }

    @observable
    public imageLightboxUrl: string | undefined;

    @action
    public revealImageLightbox(imageUrl: string | undefined, description: string | undefined) {
        this.imageLightboxUrl = imageUrl;
        this.lightboxDescription = description;
    }

    @action
    public closeLightbox() {
        this.lightboxDocuments = undefined;
        this.lightboxInitialIndex = 0;
        this.lightboxDescription = undefined;
        this.imageLightboxUrl = undefined;
    }

    @observable
    public driverProfileImageFormData: FormData = new FormData();

    @observable
    public drivingLicenseGrantedAtMonth: number;

    @observable
    public drivingLicenseGrantedAtYear: number;

    @action
    public updateDriverDrivingLicenseGrantedAtMonth(value: number) {
        this.drivingLicenseGrantedAtMonth = value;
        if (!isUndefinedOrNull(this.driverOperator.driver.drivingLicenseGrantedAt)) {
            const date = new Date(this.driverOperator.driver.drivingLicenseGrantedAt);
            this.drivingLicenseGrantedAtYear = date.getUTCFullYear();
        }
        if (
            !isUndefinedOrNull(this.drivingLicenseGrantedAtMonth) &&
            !isUndefinedOrNull(this.drivingLicenseGrantedAtYear)
        ) {
            this.updateDriverDrivingLicenseGrantedAt(
                toJS(this.drivingLicenseGrantedAtMonth),
                toJS(this.drivingLicenseGrantedAtYear),
            );
        }
    }

    @action
    public updateDriverDrivingLicenseGrantedAtYear(value: number) {
        this.drivingLicenseGrantedAtYear = value;
        if (!isUndefinedOrNull(this.driverOperator.driver.drivingLicenseGrantedAt)) {
            const date = new Date(this.driverOperator.driver.drivingLicenseGrantedAt);
            this.drivingLicenseGrantedAtMonth = date.getUTCMonth();
        }
        if (
            !isUndefinedOrNull(this.drivingLicenseGrantedAtMonth) &&
            !isUndefinedOrNull(this.drivingLicenseGrantedAtYear)
        ) {
            this.updateDriverDrivingLicenseGrantedAt(
                toJS(this.drivingLicenseGrantedAtMonth),
                toJS(this.drivingLicenseGrantedAtYear),
            );
        }
    }

    @action
    public updateDriverDrivingLicenseGrantedAt(month: number, year: number) {
        const date: Date = new Date(year, month, 1, 9);
        if (!isUndefinedOrNull(date)) {
            this.driverOperator.edit((o) => {
                (o.driver as Driver).drivingLicenseGrantedAt = toJS(date);
            });
        }
        this.drivingLicenseGrantedAtMonth = month;
        this.drivingLicenseGrantedAtYear = year;
    }

    public hireReason?: string;

    @action
    public async hireDriver() {
        await this.rpcClient.driver.hireDriver(this.driverOperator.m._id, this.hireReason);
        this.driverOperator.fetchData();
    }

    @action
    public async activeDriver() {
        await this.rpcClient.driver.activeDriver(this.driverOperator.m._id);
        this.driverOperator.fetchData();
    }

    @action
    public async declineDriver() {
        await this.rpcClient.driver.declineDriver(this.driverOperator.m._id);
        this.driverOperator.fetchData();
    }

    @action
    public cancelEdit() {
        this.driverOperator.cancelEdit();

        if (this.isNewDriver) {
            window.history.back();
        }
    }

    public profileId: string = "profileSection";

    public documentsId: string = "docuemntsSection";
}

export interface DriverProfileStoreDataFetched {
    user: User;
    simpleLocation: Array<SimpleLocation>;
    countries: Array<SimpleCountry>;
    regions: Array<Region>;
    documentTypeOperators: Array<DocumentTypeOperator>;
}
