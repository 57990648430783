import "reflect-metadata";
import "regenerator-runtime/runtime";

import "./tailwind.css";

import * as React from "react";

import { IS_PRODUCTION } from "@daytrip/legacy-config";
import { captureConsoleIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import type { Integration } from "@sentry/types";
import { render } from "react-dom";

import { initializeApolloClient } from "./apolloClient/apolloClient";
import App from "./App";
import {
    ENVIRONMENT,
    MANAGEMENT_SENTRY_DSN,
    REACT_ROOT_ELEMENT_ID,
    MANAGEMENT_TRACES_SENTRY_SAMPLE_RATE,
} from "./config.management";
import { container } from "./container";

initializeApolloClient();

const integrations: Integration[] = [
    Sentry.browserTracingIntegration(),
    captureConsoleIntegration({ levels: ["warning", "error"] }),
];

if (IS_PRODUCTION) {
    integrations.push(new Sentry.Replay());
}

Sentry.init({
    dsn: MANAGEMENT_SENTRY_DSN,
    integrations,
    ignoreErrors: [
        // Random network errors
        "Failed to fetch",
        "Load failed",
        "Fetch is aborted",
        "Missing authentication token",
        "Validation error",
    ],
    tracesSampleRate: MANAGEMENT_TRACES_SENTRY_SAMPLE_RATE,
    environment: ENVIRONMENT,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    enabled: ENVIRONMENT !== "development",
});

render(<App container={container} />, document.getElementById(REACT_ROOT_ELEMENT_ID));
