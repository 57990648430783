/* eslint-disable no-alert */

import { Location } from "@daytrip/legacy-models";
import { Order } from "@daytrip/legacy-models";
import { ChargebackStatus } from "@legacy/domain/ChargebackStatus";
import { PaymentStatus } from "@legacy/domain/PaymentStatus";
import { PaymentType } from "@legacy/domain/PaymentType";
import type { Payment } from "@legacy/models/Payment";
import { PaymentRequest } from "@legacy/models/PaymentRequest";
import { User } from "@legacy/models/User";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";

import { MANGOPAY_DASHBOARD_URL, CHECKOUT_DASHBOARD_URL, STRIPE_DASHBOARD_URL } from "../config.management";
import { globalManagementLogger } from "../global-logger";

import type { ChargebackOperator } from "./ChargebackOperator";
import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";

interface PaymentOperatorOptions extends ModelOperatorOptions<Payment, null, PaymentOperatorData> {
    onUpdate?: () => Promise<void>;
    paymentRequest?: PaymentRequest;
    paymentUser?: User;
    paymentOrder?: Order;
    orderOriginLocation?: Location;
    orderDestinationLocation?: Location;
    chargebacks?: Array<ChargebackOperator>;
}

interface PaymentOperatorData {
    isChargebackEnabled?: boolean;
}

interface PaymentOperatorDataFetched extends PaymentOperatorData {}

@autobind
export class PaymentOperator extends ModelOperator<
    Payment,
    PaymentOperatorOptions,
    null,
    PaymentOperatorData,
    PaymentOperatorDataFetched
> {
    public constructor(options: PaymentOperatorOptions) {
        super(options);

        this.onUpdate = options.onUpdate;
        this.paymentRequest = options.paymentRequest;
        this.paymentUser = options.paymentUser;
        this.paymentOrder = options.paymentOrder;
        this.orderOriginLocation = options.orderOriginLocation;
        this.orderDestinationLocation = options.orderDestinationLocation;
        this.chargebacks = options.chargebacks;
    }

    @observable
    public onUpdate?: () => void;

    @observable
    public paymentRequest?: PaymentRequest;

    @observable
    public paymentUser?: User;

    @observable
    public paymentOrder?: Order;

    @observable
    public orderOriginLocation?: Location;

    @observable
    public orderDestinationLocation?: Location;

    @observable
    public isChangingStatus = false;

    @observable
    public isChangingStatusFailed = false;

    @observable
    public isOrderConnected = false;

    @observable
    public chargebacks?: Array<ChargebackOperator>;

    @computed
    public get isChargebackEnabled(): boolean {
        return this.data.isChargebackEnabled || false;
    }

    @action
    public async unassignOrder() {
        if (!isUndefinedOrNull(this.m.orderId)) {
            await this.rpcClient.payment.unassignOrderFromPayment(this.m._id);
            this.edit((m) => (m.orderId = undefined));
        }
    }

    public async changeStatus(statusChanger: () => Promise<void>) {
        this.isChangingStatus = true;
        try {
            await statusChanger();
            if (this.onUpdate !== undefined) {
                await this.onUpdate();
            }
        } catch (e: any) {
            globalManagementLogger.error(e);
            this.isChangingStatusFailed = true;
        }
        this.isChangingStatus = false;
    }

    @action
    public async fulfill() {
        await this.changeStatus(async () => {
            if (this.status === PaymentStatus.PreAuthorized) {
                switch (this.m.type) {
                    case PaymentType.Stripe:
                        await this.rpcClient.payment.payStripePreauthorizedPayment(this.m);
                        break;
                    case PaymentType.Checkout:
                        await this.rpcClient.payment.payCheckoutPreauthorizedPayment(this.m);
                        break;
                    default:
                        throw new Error(`The payment type ${this.m.type} is not supported for pre-authorezed payments`);
                }
            } else {
                await this.rpcClient.payment.fulfillPayment(this.m._id);
            }
            this.edit((p) => (p.fulfilledAt = new Date()));
            this.data.isChargebackEnabled = await this.rpcClient.payment.validateIfEnableChargeback(this.m);
        });
    }

    @action
    public async failed() {
        await this.changeStatus(async () => {
            await this.rpcClient.payment.failPayment(this.m._id, true);
            this.edit((p) => {
                p.failedAt = new Date();
            });
        });
    }

    @observable
    public chargebackAmount: number;

    @action
    public setChargebackAmount(value: string) {
        this.chargebackAmount = Number(value);
    }

    @observable
    public chargebackDescription: string;

    @action
    public setChargebackDescription(value: string) {
        this.chargebackDescription = value;
    }

    @observable
    public chargebackType: PaymentType = this.m.type;

    @action
    public setChargebackType(value: PaymentType) {
        this.chargebackType = value;
    }

    @observable
    public isChargebackOpened: boolean = false;

    @observable
    public chargingBack: boolean = false;

    @action
    public async chargeback() {
        await this.changeStatus(async () => {
            this.chargingBack = true;

            try {
                await this.rpcClient.payment.chargebackPayment({
                    paymentId: this.m._id,
                    amount: this.chargebackAmount,
                    chargebackType: this.chargebackType,
                    description: this.chargebackDescription,
                });
            } catch (e: any) {
                globalManagementLogger.error(e);
                this.chargingBack = false;
                return;
            }

            if (
                this.m.amount === this.chargebackAmount ||
                this.m.amount === this.chargebacksAmount + this.chargebackAmount
            ) {
                await this.rpcClient.payment.setPaymentChargedBack(this.m._id);
            }

            this.chargingBack = false;
            this.isChargebackOpened = false;
        });
    }

    @computed
    public get fulfilledAmount(): number {
        if (this.m.status === PaymentStatus.Fulfilled) {
            return this.m.amount - this.chargebacksAmount;
        }
        return 0;
    }

    @computed
    public get chargebacksAmount(): number {
        if (!isUndefinedOrNull(this.chargebacks) && this.chargebacks.length > 0) {
            return this.chargebacks.reduce((s: number, co: ChargebackOperator) => {
                if (co.m.status !== ChargebackStatus.Failed) {
                    return s + co.m.amount;
                }
                return s;
            }, 0);
        }
        return 0;
    }

    @computed
    public get status(): PaymentStatus {
        if (!isUndefinedOrNull(this.m.failedAt)) {
            return PaymentStatus.Failed;
        }

        if (!isUndefinedOrNull(this.m.cancelledAt)) {
            return PaymentStatus.Cancelled;
        }

        if (!isUndefinedOrNull(this.m.fulfilledAt)) {
            if (this.m.chargedBackAt != null) {
                return PaymentStatus.ChargedBack;
            }

            return PaymentStatus.Fulfilled;
        }
        if (!isUndefinedOrNull(this.m.preAuthorizedAt)) {
            return PaymentStatus.PreAuthorized;
        }

        if (!isUndefinedOrNull(this.m.uncapturedAt)) {
            return PaymentStatus.Uncaptured;
        }

        return PaymentStatus.Pending;
    }

    @observable
    public orderIdToAssign?: string;

    @action
    public assignOrder(orderId: string): void {
        this.orderIdToAssign = orderId;
    }

    @observable
    public maxAmountForChargeback?: number = 0;

    @action
    public async getMaxAmountForChargeback(): Promise<number> {
        try {
            return await this.rpcClient.payment.getMaxAmountForChargeback(this.m, this.chargebacksAmount);
        } catch (e: any) {
            globalManagementLogger.error(e);
            return this.m.amount - this.chargebacksAmount;
        }
    }

    @action
    public async confirmAssignOrder(): Promise<void> {
        if (!isUndefinedOrNull(this.orderIdToAssign)) {
            try {
                await this.rpcClient.payment.connectPaymentWithOrder(this.m._id, this.orderIdToAssign);
                this.edit((p) => (p.orderId = this.orderIdToAssign));

                if (!isUndefinedOrNull(this.paymentRequest) && isUndefinedOrNull(this.paymentRequest.orderId)) {
                    await this.rpcClient.payment.connectPaymentRequestWithOrder(
                        this.paymentRequest._id,
                        this.orderIdToAssign,
                    );
                }

                this.isOrderConnected = true;
            } catch (e: any) {
                globalManagementLogger.error(e);
                alert("Unable to connect payment with order.");
            }
        } else {
            alert("Please select order, which you would like to connect payment with.");
        }
    }

    @action
    public async getPaymentDetailLink(): Promise<string | undefined> {
        if (!this.m.gatewayId) {
            return undefined;
        }
        switch (this.m.type) {
            case PaymentType.Mangopay:
                return `${MANGOPAY_DASHBOARD_URL}/PayIn/${this.m.gatewayId}`;
            case PaymentType.Checkout:
                try {
                    const checkoutPaymentActions = await this.rpcClient.checkout.getCheckoutPaymentActions(
                        this.m.gatewayId,
                    );
                    if (!checkoutPaymentActions.length) {
                        throw new Error(`Could not find last Checkout action for payment ${this.m.gatewayId}`);
                    }
                    const lastActionId = checkoutPaymentActions[checkoutPaymentActions.length - 1].id;
                    return `${CHECKOUT_DASHBOARD_URL}/payments/details/${lastActionId}`;
                } catch (e: any) {
                    globalManagementLogger.error(e);
                    alert("Unable to construct Checkout payment link.");
                }
                return undefined;
            case PaymentType.Stripe:
                return `${STRIPE_DASHBOARD_URL}/payments/${this.m.gatewayId}`;
            default:
                return undefined;
        }
    }

    @computed
    public get paymentDetailLabel(): string | undefined {
        switch (this.m.type) {
            case PaymentType.Mangopay:
                return "Mangopay detail";
            case PaymentType.Checkout:
                return "Checkout detail";
            case PaymentType.Stripe:
                return "Stripe detail";
            default:
                return undefined;
        }
    }
}
